/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        window.scrollTo(0, 0);

        /*var viewportWidth = $(window).width();
         var viewportHeight = $(window).height();
         var currSlick;

         $(window).resize(function() {
         var viewportWidth = $(window).width();
         var viewportHeight = $(window).height();
         });*/


        function paceOutDone() {

        }

        function closePace(e) {


        }


        var controller = new ScrollMagic.Controller();

        //navigation animations
        var navShrink = TweenMax.fromTo("#nav-primary", 1, {"margin-top": "0"}, {"margin-top": "-40px"});
        var navItemShrink = TweenMax.fromTo(".main-nav .nav.desktop li", 1, {"padding-top": "68px"}, {"padding-top": "56px"});
        var navExternalShrink = TweenMax.fromTo(".main-nav .nav-external", 1, {"padding-top": "68px"}, {"padding-top": "56px"});
        var logoShrink = TweenMax.fromTo(".logo-nav", 1, {"width": "154px", "height": "154px"}, {
          "width": "120px",
          "height": "120px"
        });
        var logoTextShrink = TweenMax.fromTo(".logo-text", 1, {"width": "154px", "height": "154px"}, {
          "width": "120px",
          "height": "120px"
        });
        new ScrollMagic.Scene({
          triggerHook: "onLeave",
          triggerElement: "body",
          offset: "10",
          duration: 100
        }).setTween(navShrink).addTo(controller);
        new ScrollMagic.Scene({
          triggerHook: "onLeave",
          triggerElement: "body",
          offset: "10",
          duration: 100
        }).setTween(logoShrink).addTo(controller);
        new ScrollMagic.Scene({
          triggerHook: "onLeave",
          triggerElement: "body",
          offset: "10",
          duration: 100
        }).setTween(logoTextShrink).addTo(controller);
        new ScrollMagic.Scene({
          triggerHook: "onLeave",
          triggerElement: "body",
          offset: "10",
          duration: 100
        }).setTween(navItemShrink).addTo(controller);
        new ScrollMagic.Scene({
          triggerHook: "onLeave",
          triggerElement: "body",
          offset: "10",
          duration: 100
        }).setTween(navExternalShrink).addTo(controller);
        var navGoDark = TweenMax.to("#nav-primary", 0.1, {className: '+=dark'});
        new ScrollMagic.Scene({
          triggerHook: "onLeave",
          triggerElement: ".go-dark",
          offset: -50
        }).setTween(navGoDark).addTo(controller);

        var navGoDarkMobile = TweenMax.to("#nav-primary", 0.1, {className: '+=dark_mobile'});
        new ScrollMagic.Scene({
          triggerHook: "onLeave",
          triggerElement: ".main-weh",
          offset: 2
        }).setTween(navGoDarkMobile).addTo(controller);
        //new ScrollMagic.Scene({triggerHook: "onLeave", triggerElement: ".go-dark", offset:-250}).setClassToggle("#nav-primary", "dark").addTo(controller);
        var investOut = TweenMax.fromTo(".investor-bug", 1, {"opacity": "1", "right": "-20px"}, {
          "opacity": "0",
          "right": "-70px"
        });
        new ScrollMagic.Scene({
          triggerElement: ".invest",
          offset: -100,
          duration: 100
        }).setTween(investOut).addTo(controller);
        var investIn = TweenMax.fromTo(".investor-bug", 1, {"opacity": "0", "right": "-70px"}, {
          "opacity": "1",
          "right": "-20px"
        });
        new ScrollMagic.Scene({
          triggerHook: "onLeave",
          triggerElement: ".show-invest",
          offset: -280,
          duration: 100
        }).setTween(investIn).addTo(controller);

        var investMobileOut = TweenMax.fromTo(".mobile-foot-invest", 1, {
          "opacity": "1",
          "bottom": "0px"
        }, {"opacity": "0", "bottom": "-56px"});
        new ScrollMagic.Scene({
          triggerElement: ".invest",
          offset: -100,
          duration: 100
        }).setTween(investMobileOut).addTo(controller);
        var investMobileIn = TweenMax.fromTo(".mobile-foot-invest", 1, {
          "opacity": "0",
          "bottom": "-56px"
        }, {"opacity": "1", "bottom": "0"});
        new ScrollMagic.Scene({
          triggerHook: "onLeave",
          triggerElement: ".show-invest",
          offset: -400,
          duration: 100
        }).setTween(investMobileIn).addTo(controller);


        //general animations
        $(".reveal").each(function (index, elem) {
          var fadeIn = TweenMax.fromTo(this, 0.5, {"opacity": "0"}, {"opacity": "1", ease: Power0.easeNone});
          var revealScene = new ScrollMagic.Scene({
            triggerHook: "0.8",
            triggerElement: this
          }).setTween(fadeIn).addTo(controller);
        });

        $('.scroll-to').click(function (e) {
          e.preventDefault();


          var targetElem = $(this).attr('data-link');

          if (targetElem == "top") {
            var destination = 0;
            var scrollEl = "html, body";
          } else if (targetElem == "top-modal") {
            var destination = 0;
            var scrollEl = ".modal";
          } else {
            var destination = $(targetElem).offset().top;
            var scrollEl = "html, body";
          }


          $(scrollEl).animate({
            scrollTop: destination
          }, 1000);
        });


        $("#nav-control").click(function () {
          $(".nav-overlay").addClass("open");
          $(".mobile-open").addClass("hide");
          $(".nav-overlay").removeClass("close");
        });

        $(".current-page-nav").click(function (e) {
          e.preventDefault;
          $(".nav-overlay").removeClass("open");
          $(".mobile-open").removeClass("hide");
          $(".nav-overlay").addClass("close");
        });

        $(".menu-item").click(function (e) {
          $(".nav-overlay").removeClass("open");
          $(".mobile-open").removeClass("hide");
          $(".nav-overlay").addClass("close");
        });

        $(".nav-close-behind").click(function (e) {
          $(".nav-overlay").removeClass("open");
          $(".mobile-open").removeClass("hide");
          $(".nav-overlay").addClass("close");
        });


        $('.mobile-foot-invest').click(function (e) {
          //e.preventDefault();

          //var targetElem = $(this).attr('href');

          $('html, body').animate({
            scrollTop: $(".invest").offset().top
          }, 800);
        });


        /*$( ".img-slide" ).each(function( index, elem ) {
         settings = {
         theSlider: $(this),
         imgsWidth: $(this).width()
         };

         $(this).imgSlide(settings);

         });*/


        var theCircle;
        var relParent;
        var launchedLink;

        function addClosure(elToClose) {
          $(elToClose).click(function (e) {
            e.preventDefault;
            $(this).removeClass('launch');
            $('body').removeClass('no-scroll');
            //$('body').removeClass('disable-scrolling');
            theCircle = $(this).find("span");
            relParent = $(this).closest('.vert-middle').removeClass('unpos');
            //TweenMax.to(theCircle, 0.4, {scale:1, backgroundColor:"#e7ca54", ease: Power1.easeInOut});
            TweenMax.fromTo(theCircle, 0.4, {scale: 80}, {scale: 1, ease: Power1.easeInOut});
            TweenMax.to(theCircle, 0.4, {backgroundColor: "#e7ca54", delay: 0.1, ease: Power1.easeInOut});

          });
        };

        $(".gold-link").click(function (e) {
          e.preventDefault;
          //addClosure($(this));
          launchModal($(this), null);
        });

        function reloadContent() {

          setTimeout(function () {
            $('.gold-link').removeClass('launch');
          }, 500);
        };

        function closeModal(activeSlick) {
          //e.preventDefault;
          reloadContent();
          $('body').removeClass('no-scroll');
          //$('body').removeClass('disable-scrolling');
          
          try {
            launchedLink.closest('.vert-middle').removeClass('unpos');  
          } catch (e) {
            
          }          
          
          if (theCircle) {
            //$('#modal-wrapper').removeClass('open');
            TweenMax.fromTo(theCircle, 0.4, {scale: 80}, {scale: 1, ease: Power1.easeInOut});
            TweenMax.to(theCircle, 0.4, {backgroundColor: "#e7ca54", delay: 0.1, ease: Power1.easeInOut});

            //TweenMax.to(theCircle, 0.4, {width:26, height:26, x:0, y:0, backgroundColor: "#e7ca54", ease: Power1.easeOut});

          }

          try {
            setTimeout(function () {
              currSlick.slick('unslick');
            }, 200);
          } catch (e) {

          } finally {

          }


        };

        $("#btn-modal-close").click(function (e) {
          closeModal();
        });

        function launchModal(theLauncher, theModal) {
          theLauncher.addClass('launch');
          if (theLauncher.hasClass('freeze')) {
            $('body').addClass('no-scroll');
          }
          //$('body').addClass('disable-scrolling');
          launchedLink = $(this);
          theCircle = theLauncher.find("span");
          relParent = theLauncher.closest('.vert-middle').addClass('unpos');
          //$('#modal-wrapper').addClass('open');
          TweenMax.to(theCircle, 0.4, {scale: 50, backgroundColor: "#313939", ease: Power1.easeOut});
          //TweenMax.to(theCircle, 0.4, {width:1000, height:1000, x:-500, y:-500, backgroundColor: "#313939", ease: Power1.easeOut});
        };


        var $window = $(window);
        var startScrollPos;
        var currScrollPos;
        var distanceToClose;
        var distanceToCloseUp;

        function closeInvestBug() {
          $(".invest-circle-bg").removeClass("open");
          $(".invest-circle-info").removeClass("open");
          $(".close-trigger").removeClass("active");
          $(".go-invest").removeClass("open");

          $(".close-trigger").unbind();
          $(".invest-circle-bg").unbind();
        }


        function launchInvestBug() {
          $(".invest-circle-bg").addClass("open");
          $(".invest-circle-info").addClass("open");
          $(".go-invest").addClass("open");
          $(".close-trigger").addClass("active");

          $(".close-trigger").mouseover(function () {
            closeInvestBug();
          });
          $(".invest-circle-bg").click(function () {
            closeInvestBug();
          });

          startScrollPos = $window.scrollTop();
          distanceToClose = startScrollPos + 200;
          distanceToCloseUp = startScrollPos - 200;

          window.addEventListener('scroll', function (e) {
            currScrollPos = $window.scrollTop();

            if (currScrollPos > distanceToClose) {
              closeInvestBug();
              window.addEventListener('removeEventListener');
            } else if (currScrollPos < distanceToCloseUp) {
              closeInvestBug();
              window.addEventListener('removeEventListener');
            }

            //var docViewTop = $window.scrollTop();
            //var docViewBottom = docViewTop + $window.height();
          });

        }


        $(".go-invest").click(function () {
          launchInvestBug();
        });

        function closeGallery() {
          $('.modal').modal('hide');
          closeModal();
        }


        $(".modal-fullscreen").on('show.bs.modal', function (e) {
          setTimeout(function () {
            var theModal = e.currentTarget;
            var theShow = $(theModal).find('.slides');
            theShow.addClass('show');
            theShow.slick({
              dots: false,
              infinite: false,
              speed: 400,
              fade: false,
              accessibility: true,
              useTransform: true,
              cssEase: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
              responsive: [
                {
                  breakpoint: 991,
                  settings: {
                    dots: true,
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
            });

            currSlick = theShow;

            theShow.on('afterChange', function (event, slick, currentSlide, nextSlide) {
              /*$('.slick-prev').unbind('click', function() {
               alert('The quick brown fox jumps over the lazy dog.');
               });
               $('.slick-next').unbind('click', function() {
               alert('The quick brown fox jumps over the lazy dog.');
               });
               $( ".slick-disabled" ).click(function(e) {
               console.log("i has class");
               $('.modal').modal('hide');
               closeModal();
               });*/


            });
            theShow.on('init', function (event, slick, currentSlide, nextSlide) {
              /*$('.slick-arrow').unbind('click', function() {
               alert('The quick brown fox jumps over the lazy dog.');
               });
               $( ".slick-disabled" ).click(function(e) {
               $('.modal').modal('hide');
               closeModal();
               });*/
            });
          }, 200);
        });

        $(".modal-fullscreen").on('hide.bs.modal', function () {
          closeModal();
        });

        /*

         document.ontouchmove = function ( event ) {

         var isTouchMoveAllowed = true, target = event.target;

         while ( target !== null ) {
         if ( target.classList && target.classList.contains( 'disable-scrolling' ) ) {
         isTouchMoveAllowed = false;
         break;
         }
         target = target.parentNode;
         }

         if ( !isTouchMoveAllowed ) {
         event.preventDefault();
         }

         };

         */

        //$(".viewport").mCustomScrollbar();


        /*$(window).mousemove(function(event) {
         theY = event.pageY - 900;
         $('.cursor-close').css({
         'top' : theY + 'px',
         'left' : event.pageX + 'px'
         });
         });*/


      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        window.onunload = function () {
          //close mobile menu for forward and back buttons in mobile safari
          $(".nav-overlay").removeClass("open");
          $(".mobile-open").removeClass("hide");
          $(".nav-overlay").addClass("close");
        };

        $(window).sr = new scrollReveal({
          //reset: true,
          delay: 'onload',
          mobile: true
        });

        //window.scrollTo(0, 0);

      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on all pages
        /*var viewportWidth = $(window).width();
         var viewportHeight = $(window).height();
         var newHeight = viewportHeight + 40;
         $('.banner-vid').css('height', newHeight);
         $('.banner').css('height', newHeight);

         $(window).resize(function() {
         var viewportWidth = $(window).width();
         var viewportHeight = $(window).height();
         var newHeight = viewportHeight + 40;
         $('.banner-vid').css('height', newHeight);
         $('.banner').css('height', newHeight);

         });*/

        $('.banner-vid').vide('./wp-content/themes/west-elm/assets/video/1-Homepage 3sm', {
          loop: false,
          autoplay: false
        });
        var visionBannerVide = $('.banner-vid').data().vide;

        var $bringingTravelersVideoEl = $('.bringing-travelers .full-vid');
        $bringingTravelersVideoEl.vide('./wp-content/themes/west-elm/assets/video/2-Sametable_outsm', {
          loop: false,
          autoplay: false
        });
        var bringingTravelersVideo = {
          $el:    $bringingTravelersVideoEl,
          video:  $bringingTravelersVideoEl.data().vide.getVideoObject()
        };
        console.log("bringingTravelersVideo", bringingTravelersVideo);

        var homeController = new ScrollMagic.Controller();

        var vid = visionBannerVide.getVideoObject();
        vid.play();

        new ScrollMagic.Scene({
          duration: "100%",
          triggerElement: ".bringing-travelers .full-vid"
        })
          .on('enter', function (e) {
            var vid = bringingTravelersVideo.video;
            // console.log('visionBringingTravelersVide',visionBringingTravelersVide);
            // console.log('vid', vid);
            if(vid.paused) {
              vid.currentTime = 0;
              vid.play();
            }

          })
          .addTo(homeController);


        //var bannerTween = TweenMax.fromTo(".banner-cont", 1, {"margin-top": "0"}, {"margin-top": "140px", ease: Power1.easeOut});
        var bannerTween = TweenMax.to(".banner-cont-inner", 1, {y: 60, autoRound: false});
        new ScrollMagic.Scene({
          triggerHook: "onLeave",
          triggerElement: ".banner-vid",
          duration: "100%"
        })
          .setTween(bannerTween)
          .addTo(homeController)
          .on('enter', function(e){
            var vid = visionBannerVide.getVideoObject();
            // console.log('visionBannerVide',visionBannerVide);
            // console.log('vid', vid);
            if(vid.paused) {
              vid.currentTime = 0;
              vid.play();
            }
          });


        $(".modal-fullscreen-video").on('show.bs.modal', function (e) {
          $('.icon-vid').addClass('open');

          $(".modal-fullscreen-video .close-area").click(function (e) {
            $('#modal-video').modal('hide');
          });

          var wehVideo = new MediaElementPlayer('#player1', {
            videoWidth: '100%',
            videoHeight: '100%',
            enableAutosize: true,
            loop: false,
            alwaysShowControls: true,
            success: function (mediaElement) {
              //console.log('yay');
              mediaElement.addEventListener('ended', function (e) {
                mediaElement.setCurrentTime(0.1);
                mediaElement.pause();
                $('#modal-video').modal('hide');
                //$('video').get(0).webkitExitFullscreen();
                //if (typeof mediaElement.webkitExitFullscreen !== "undefined") {
                //mediaElement.webkitExitFullscreen();
                //}

              }, false);

              $(".modal-fullscreen-video").on('hidden.bs.modal', function () {
                $('.icon-vid').removeClass('open');
                //wehVideo.pause();
                //closeModal();
                try {
                  //$('.mejs-mediaelement iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
                  mediaElement.pause();
                  //$('.mejs-mediaelement iframe').attr('src','');
                } catch (e) {

                } finally {

                }

              });

            }
          });

          //setTimeout( function() {
          //wehVideo.play();

          //}, 200);
        });


      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        //window.scrollTo(0, 0);

      }
    },
    // About us page, note the change from about-us to about_us.
    'partnership': {
      init: function () {
        console.log("/partnership");

        // JavaScript to be fired on the about us page
        //var viewportWidth = $(window).width();
        //var viewportHeight = $(window).height();
        //var newHeight = viewportHeight + 40;
        //$('.banner-vid').css('height', newHeight);
        //$('.banner').css('height', newHeight);
        //$('body').css('padding-top', newHeight*0.40);

        //$(window).resize(function() {
        //var viewportWidth = $(window).width();
        //var viewportHeight = $(window).height();
        //var newHeight = viewportHeight + 40;
        //$('.banner-vid').css('height', newHeight);
        //$('.banner').css('height', newHeight);
        //$('body').css('padding-top', newHeight*0.40);

        //});
      },
      finalize: function () {
        //window.scrollTo(0, 0);

      }
    },
    'locations': {
      init: function () {
        // JavaScript to be fired on the locations page

        ////var photo1Tween = TweenMax.fromTo(".photo", 1, {"opacity": "0", "margin-top": "5%"}, {"opacity": "1", "margin-top": "-5%", ease: Power0.easeNone});
        ///new ScrollMagic.Scene({triggerElement: ".details", offset:0, duration: "35%"}).setTween(photo1Tween).addIndicators({name: "tween"}).addTo(locationsController);

        ////var photoMoveTween = TweenMax.fromTo(".photo", 1, {"margin-top": "5%"}, {"margin-top": "-5%", ease: Power0.easeNone});
        //var photoOpacityInTween = TweenMax.fromTo(".photo", 1, {"opacity": "0"}, {"opacity": "1", ease: Power0.easeNone});
        //var photoOpacityOutTween = TweenMax.fromTo(".photo", 1, {"opacity": "1"}, {"opacity": "0", ease: Power0.easeNone});
        ////new ScrollMagic.Scene({triggerElement: ".details", offset:0, duration: "35%"}).setTween(photoMoveTween).addIndicators({name: "move tween"}).addTo(locationsController);
        //new ScrollMagic.Scene({triggerElement: ".details", offset:"25%", duration: "12.5%"}).setTween(photoOpacityOutTween).addIndicators({name: "opacity tween"}).addTo(locationsController);
        //new ScrollMagic.Scene({triggerElement: ".details", offset:-40, duration: "12.5%"}).setTween(photoOpacityInTween).addIndicators({name: "opacity tween"}).addTo(locationsController);


        //new ScrollMagic.Scene({triggerElement: ".deet1", offset:"-50vw", duration: "450vw"}).setPin(".anim1").addIndicators({name: "pin (duration: 300)"}).addTo(locationsController);
        //new ScrollMagic.Scene({triggerElement: ".deet2", offset:"-50vw", duration: "450vw"}).setPin(".anim2").addIndicators({name: "pin (duration: 300)"}).addTo(locationsController);
        //new ScrollMagic.Scene({triggerElement: ".deet3", offset:"-50vw", duration: "450vw"}).setPin(".anim3").addIndicators({name: "pin (duration: 300)"}).addTo(locationsController);
        //new ScrollMagic.Scene({triggerElement: ".deet4", offset:"-50vw", duration: "450vw"}).setPin(".anim4").addIndicators({name: "pin (duration: 300)"}).addTo(locationsController);
        ////new ScrollMagic.Scene({triggerElement: ".details", offset:0, duration: "35%"}).setTween(photo1Tween).addIndicators({name: "tween"}).addTo(locationsController);


        var locationsController = new ScrollMagic.Controller();
        var theTimeline;

        // $(".local").each(function (index) {

        //   animID = $(this).attr('id');
        //   theDeet = '.deet-' + animID;

        //   theTimeline = new TimelineMax({repeat: 0, repeatDelay: 1});

        //   theTimeline.to($(this).find('.anim-' + animID), 2, {"opacity": "1", yPercent: -15, ease: Power4.easeOut});
        //   theTimeline.to($(this).find('.anim-' + animID), 2, {"opacity": "1", yPercent: -20, ease: Power0.easeNone});
        //   theTimeline.to($(this).find('.anim-' + animID), 1, {"opacity": "0", yPercent: -35, ease: Power0.easeNone});

        //   theDeetTimeline = new TimelineMax({repeat: 0, repeatDelay: 1});

        //   theDeetTimeline.to($(this).find('.deet-' + animID), 2, {"opacity": "1", ease: Power4.easeOut});
        //   theDeetTimeline.to($(this).find('.deet-' + animID), 2, {"opacity": "1", ease: Power0.easeNone});
        //   theDeetTimeline.to($(this).find('.deet-' + animID), 1, {"opacity": "0.3", ease: Power0.easeNone});

        //   //photo scene
        //   new ScrollMagic.Scene({
        //     triggerElement: theDeet,
        //     offset: "-20vw",
        //     duration: "420vw"
        //   }).setTween(theTimeline).addTo(locationsController);

        //   //details scene
        //   new ScrollMagic.Scene({
        //     triggerElement: theDeet,
        //     offset: "-20vw",
        //     duration: "420vw"
        //   }).setTween(theDeetTimeline).addTo(locationsController);


        //   //locations always use dark nav, just in case they miss the trigger by scrolling too fast before page load
        //   $('#nav-primary').addClass('dark');

        // });


        /*var tlDeets = new TimelineMax({repeat:0, repeatDelay:1});
         tlDeets.fromTo(".deet1", 1, {"opacity": "0.5"}, {"opacity": "1", ease: Power1.easeOut});
         tlDeets.fromTo(".deet1", 1, {"opacity": "1"}, {"opacity": "1", ease: Power0.easeNone});
         tlDeets.fromTo(".deet1", 1, {"opacity": "1"}, {"opacity": "0.5", ease: Power1.easeIn});
         new ScrollMagic.Scene({triggerElement: ".deet1", offset:"-20vw", duration: "330vw"}).setTween(tlDeets).addTo(locationsController);

         var tlDeets2 = new TimelineMax({repeat:0, repeatDelay:1});
         tlDeets2.fromTo(".deet2", 1, {"opacity": "0.5"}, {"opacity": "1", ease: Power1.easeOut});
         tlDeets2.fromTo(".deet2", 1, {"opacity": "1"}, {"opacity": "1", ease: Power0.easeNone});
         tlDeets2.fromTo(".deet2", 1, {"opacity": "1"}, {"opacity": "0.5", ease: Power1.easeIn});
         new ScrollMagic.Scene({triggerElement: ".deet2", offset:"-20vw", duration: "330vw"}).setTween(tlDeets2).addTo(locationsController);

         var tlDeets3 = new TimelineMax({repeat:0, repeatDelay:1});
         tlDeets3.fromTo(".deet3", 1, {"opacity": "0.5"}, {"opacity": "1", ease: Power1.easeOut});
         tlDeets3.fromTo(".deet3", 1, {"opacity": "1"}, {"opacity": "1", ease: Power0.easeNone});
         tlDeets3.fromTo(".deet3", 1, {"opacity": "1"}, {"opacity": "0.5", ease: Power1.easeIn});
         new ScrollMagic.Scene({triggerElement: ".deet3", offset:"-20vw", duration: "330vw"}).setTween(tlDeets3).addTo(locationsController);

         var tlDeets4 = new TimelineMax({repeat:0, repeatDelay:1});
         tlDeets4.fromTo(".deet4", 1, {"opacity": "0.5"}, {"opacity": "1", ease: Power1.easeOut});
         tlDeets4.fromTo(".deet4", 1, {"opacity": "1"}, {"opacity": "1", ease: Power0.easeNone});
         tlDeets4.fromTo(".deet4", 1, {"opacity": "1"}, {"opacity": "0.5", ease: Power1.easeIn});
         new ScrollMagic.Scene({triggerElement: ".deet4", offset:"-20vw", duration: "330vw"}).setTween(tlDeets4).addTo(locationsController);*/


      }
    },
    finalize: function () {
      //window.scrollTo(0, 0);

    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
